.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 64px);
}

.login-card {
    margin: auto;
    overflow: hidden;
    width: 700px;
    max-width: 90%;
    background-color: #fdfdfd !important;
    box-shadow: 4px 4px 90px rgba(0, 0, 0, 0.15) !important;
    border-radius: 30px !important;
    text-align: center;
    padding: 40px;
}

@media (max-width: 600px) {
    .login-card {
        box-shadow: none !important;
        border-radius: 0px !important;
    }
}