.backdrop {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.562);
}
.container {
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 100%;
  max-width: 700px;
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  max-height: calc(100vh - 40px);
}
.innercontainer {
  padding: 30px;
  width: 100%;
  overflow-y: auto;
  position: relative;
  flex: 1;
}
.component {
  max-width: 500px;
  margin: 20px auto;
}
h1 {
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
  text-align: center;
}
