.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
}

.entry {
  display: flex;
  gap: 10px;
}

.inputArea {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.form input,
.form select {
  padding: 5px 10px;
  background-color: rgb(206, 206, 206);
  border: none;
}

.form select,
.form input[type="file"] {
  cursor: pointer;
}
